import { AuthenticationProvider } from "@artifactlabs/react-auth";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import { useIsClient } from "usehooks-ts";

import { getOrgAuth0ConfigByTenantId } from "@/utils/org";

export interface Props {
  tenantId: string;
  children?: ReactNode;
}

const AppAuthenticationProvider: React.FunctionComponent<Props> = ({ tenantId, children }) => {
  const router = useRouter();
  const isClient = useIsClient();

  if (!isClient) {
    return null;
  }

  const { domain, clientId } = getOrgAuth0ConfigByTenantId(tenantId);

  return (
    <>
      <AuthenticationProvider
        clientId={clientId}
        domain={domain}
        redirectUrl={`${window.location.origin}/callback`}
        onRedirectCallback={appState => {
          router.push(
            appState && appState.returnTo
              ? appState.returnTo
              : `${window.location.pathname}${window.location.search}`,
          );
        }}
      >
        {children}
      </AuthenticationProvider>
    </>
  );
};

export default AppAuthenticationProvider;
