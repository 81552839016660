//

export const map_config = {
  //
  contactEmail: "radhika.poddar@map-india.org",
  //
  allowMobileViewOnDev: false,

  defaultCurrency: "USD",

  hideContractInfo: true,

  hideCreationDateFilter: true,

  hideRelevantDatesFilter: true,

  hideCreatorFilter: true,

  relevantGeosFacetShowSearchbarFilter: true,

  assetTypeFacetShowSearchbarFilter: true,
};
