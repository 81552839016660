// utils/loadFont.js

import Head from "next/head";

const fontsImports = {
  poppins:
    "https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap",
  roboto:
    "https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap",
  playfairDisplay:
    "https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap",
};

// Dynamic font imports for different tenants
const LoadFontsByTenant = ({ tenantId }: { tenantId: string }) => {
  switch (tenantId) {
    case "scmp":
      return (
        <Head>
          <link href={fontsImports?.roboto} rel="stylesheet" />
          <link href={fontsImports?.playfairDisplay} rel="stylesheet" />
        </Head>
      );
    default:
      return (
        <Head>
          <link href={fontsImports?.poppins} rel="stylesheet" />
        </Head>
      );
  }
};

export default LoadFontsByTenant;
